var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"payment payment-completed"},[_c('Breadcrumb',{staticClass:"hidden-sm-and-down px-0",attrs:{"items":_vm.breadcrumbs}}),_c('CategoryTitle',{staticClass:"ma-7",attrs:{"category":_vm.category}}),_c('div',{staticClass:"d-flex align-center flex-column justify-center flex-wrap title-wrap px-2"},[(_vm.paymentFailed)?_c('h2',{staticClass:"mb-10 ko text-uppercase text-center"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.title"))+" ")]):_c('h2',{staticClass:"mb-10 ok text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.success.title"))+" ")])]),_c('v-card',{staticClass:"card text-center mx-auto py-6",attrs:{"elevation":"0"}},[(_vm.paymentFailed)?_c('v-card-text',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.messageStart"))+" "),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(_vm._s(_vm.order.orderId))]),_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.messageEnd"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.failure.retry"))+" ")],1)]):_vm._e(),(!_vm.paymentFailed && _vm.order.orderStatusId === 3)?_c('v-card-text',[(_vm.order.shippingAddress.deliveryServiceId === 1)?[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.1.title"))+" ")]),_c('h2',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(" "+_vm._s(_vm.order.orderId)+" ")])],1),_c('p',{staticClass:"mt-5"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.1.selectedPoint"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.order.shippingAddress.addressTypeId + ".format", _vm.order.shippingAddress )))])])]:(
          _vm.order.shippingAddress.deliveryServiceId === 2 ||
            _vm.order.shippingAddress.deliveryServiceId === 6
        )?[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.2.title"))+" ")]),_c('h2',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{ name: 'Order', params: { orderId: _vm.order.orderId } }}},[_vm._v(" "+_vm._s(_vm.order.orderId)+" ")])],1),_c('p',[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.deliveryService.2.selectedPoint"))+" "),_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "navbar.address." + _vm.order.shippingAddress.addressTypeId + ".format", _vm.order.shippingAddress ))+" ")])])]:_vm._e()],2):_vm._e()],1),(_vm.showPayment)?_c('PaymentTypeList',{staticClass:"payment-methods mt-8 mx-auto",attrs:{"order-id":_vm.order.orderId,"options":{
      showPaymentMethodImage: true,
      confirm: { color: 'secondary' }
    }}}):_vm._e(),(!_vm.paymentFailed)?_c('v-container',{staticClass:"edit-order justify-center d-flex flex-column flex-sm-row",attrs:{"justify":"center","no-gutters":""}},[_c('div',{staticClass:"text-center text-sm-left"},[_c('h4',{staticClass:"text-uppercase mb-3 mb-sm-0"},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.edit.title"))+" ")]),_c('i18n',{attrs:{"path":"paymentCompleted.edit.desc","tag":"span"}},[_c('a',{attrs:{"place":"orders","href":"/profile/orders"}},[_vm._v(_vm._s(_vm.$t("paymentCompleted.edit.link")))])])],1)]):_vm._e(),(!_vm.paymentFailed)?_c('v-container',{staticClass:"delivery-warning text-center text-sm-left mt-7"},[_c('span',[_vm._v(_vm._s(_vm.$t("paymentCompleted.deliveryService.warning")))])]):_vm._e(),_c('v-container',{staticClass:"buttons d-flex justify-center",class:_vm.$vuetify.breakpoint.xsOnly && _vm.paymentFailed ? 'flex-column' : 'flex-row'},[_c('v-btn',{staticClass:"mx-2 mx-sm-10",attrs:{"outlined":"","color":"primary","to":'/'}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.home"))+" ")]),(_vm.$vuetify.breakpoint.lgAndUp && _vm.paymentFailed)?_c('v-spacer'):_vm._e(),(_vm.paymentFailed)?_c('v-btn',{staticClass:"justify-self-md-end my-6 my-sm-0 mx-2 mx-sm-10",attrs:{"color":"secondary"},on:{"click":_vm.toggleShowPayment}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.retry"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"mx-2 mx-sm-10",attrs:{"to":'/profile/orders/' + _vm.order.orderId,"color":"secondary","depressed":""}},[_vm._v(" "+_vm._s(_vm.$t("paymentCompleted.button.showOrder"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }